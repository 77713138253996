import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormPageStore } from "store/FormPageStore";
// import { useFormValidationSchema } from "./useFormValidationSchema";
import { useYupValidationResolver } from "utils/setYupLocale";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Flex, NavigationBar } from "components/styled";
import { routes, useCurrentRoute } from "config/routes";
import { useNavigate, useParams } from "react-router-dom";
import { useAppStore } from "store/AppStore";
import { ThemeProvider } from "@greco/components";
import { ArrowLeftFilled } from "@fluentui/react-icons";
import { useSearchParams } from "react-router-dom";

import {
  Button,
  Tab,
  TabList,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";

// import ReviewSection from "./sections/ReviewSection";

import MainTabList from "./MainTabList";
import MainTabCard from "./MainTabCard";
import {
  setCityOptions,
  setContractTypeOptions,
  setFuelTypeOptions,
  setInsurerCascoOptions,
  setInsurerMtplOptions,
  setSicGroupOptions,
  setSubcategoryOptions,
  setVehicleRegistrationOptions,
  transformVehiclePayload,
} from "utils/utils";
import { InsuranceProductType, insuranceProducts } from "types/products";
import { taxonomyFlat } from "utils/types";
import { useFormValidationSchema } from "./useFormValidationSchema";

const useStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    width: "768px",
    maxWidth: "100%",
    marginTop: "20px",
    justifyContent: "center",
  },
});

const FormPageNew = () => {
  const { t } = useTranslation();
  const { itemId } = useParams() as any;
  const [searchParams] = useSearchParams();
  let product = searchParams.get("product");
  let selectedTab = searchParams.get("selectedTab");

  const route = useCurrentRoute();
  const navigate = useNavigate();
  const schema = useFormValidationSchema(yup, t);

  const resolver = useYupValidationResolver(schema);

  const formPageStore = useFormPageStore();
  const [
    { offer, mainTab, offerLoadStatus, repeatOfferPayload, selectedProduct },
    { loadOffer, duplicateOffer, setFormStoreValue, setFormStoreValues, reset },
  ] = formPageStore;
  const isLoading = offerLoadStatus === "loading";

  const [{ appState, appInfoData }, { setAppStoreValue }] = useAppStore();

  useEffect(() => {
    if (route === "formPageNewOfferProduct") {
      if (!product && !repeatOfferPayload) {
        navigate(routes.listPage.path);
      }
      if (!repeatOfferPayload) {
        setFormStoreValue("selectedProduct", insuranceProducts[product]);
      }
    }
    //reset();
    return () => {
      reset();
      //clearAllNotifications();
    };
  }, [product, repeatOfferPayload]);

  useEffect(() => {
    if (itemId) {
      loadOffer(
        t,
        {
          appInfo: {
            dataObj: {
              offerId: itemId,
            },
            resolvedProperties: {
              title: "BCR Leasing",
            },
          },
        },
        (title, data) => {
          setAppStoreValue("appState", {
            ...appState,
            headerConfig: { ...appState.headerConfig, application_name: title },
          });
          const inp_vehicle_category = data.inp_vehicle_category
            ? taxonomyFlat["inp_vehicle_category"][data.inp_vehicle_category]
            : null;
          const inp_lessee_county = data.inp_lessee_county
            ? taxonomyFlat["inp_lessee_county"][data.inp_lessee_county]
            : null;
          setFormStoreValues({
            subCategories: setSubcategoryOptions(inp_vehicle_category, t),
            cities: setCityOptions(inp_lessee_county, t),
            contractTypeOptions: setContractTypeOptions(data, t),
            fuelTypeOptions: setFuelTypeOptions(data, t),
            vehicleRegistrationOptions: setVehicleRegistrationOptions(data, t),
            sicCodeOptions: setSicGroupOptions(data, t),
            insurerCascoList: setInsurerCascoOptions(data, t),
            insurerMtplList: setInsurerMtplOptions(data, t),
          });
        }
      );
      // } else {
      //   if (route === "formPageNewOfferMtpl") {
      //     loadOffer(
      //       {
      //         appInfo: {
      //           dataObj: {
      //             offerRequestType: "F100056",
      //           },
      //           resolvedProperties: {
      //             title: "BCR Leasing",
      //           },
      //         },
      //       },
      //       (title) =>
      //         setAppStoreValue("appState", {
      //           ...appState,
      //           headerConfig: {
      //             ...appState.headerConfig,
      //             application_name: title,
      //           },
      //         })
      //     );
      //   }
    }
  }, [itemId]);

  useEffect(() => {
    if (!appInfoData || !selectedProduct || repeatOfferPayload) return;
    if (!itemId && selectedProduct) {
      loadOffer(
        t,
        {
          appInfo: {
            dataObj: {
              offerRequestType:
                selectedProduct?.type === InsuranceProductType.generalBusiness
                  ? "F100056"
                  : appInfoData?.offerRequestType,
            },
            resolvedProperties: {
              title: "BCR Leasing",
            },
          },
        },
        (title, data) => {
          setAppStoreValue("appState", {
            ...appState,
            headerConfig: {
              ...appState.headerConfig,
              application_name: title,
            },
          });
          const inp_vehicle_category = data.inp_vehicle_category
            ? taxonomyFlat["inp_vehicle_category"][data.inp_vehicle_category]
            : null;
          const inp_contract_type = data.inp_contract_type
            ? taxonomyFlat["inp_contract_type"][data.inp_contract_type]
            : null;
          const inp_lessee_county = data.inp_lessee_county
            ? taxonomyFlat["inp_lessee_county"][data.inp_lessee_county]
            : null;
          setFormStoreValues({
            subCategories: setSubcategoryOptions(inp_vehicle_category, t),
            cities: setCityOptions(inp_lessee_county, t),
            contractTypeOptions: setContractTypeOptions(data, t),
            fuelTypeOptions: setFuelTypeOptions(data, t),
            vehicleRegistrationOptions: setVehicleRegistrationOptions(data, t),
            sicCodeOptions: setSicGroupOptions(data, t),
            insurerCascoList: setInsurerCascoOptions(data, t),
            insurerMtplList: setInsurerMtplOptions(data, t),
            selectedCascoInsurers: [
              "omniasig",
              "asirom",
              "allianz",
              "groupama",
            ],
            selectedMtplInsurers: [
              "omniasig",
              "asirom",
              "allianz",
              "generali",
              "groupama",
              "grawe",
            ],
          });
        }
      );
    }
  }, [appInfoData, repeatOfferPayload, itemId, route, selectedProduct?.type]);

  useEffect(() => {
    if (!appInfoData || selectedProduct || !repeatOfferPayload) return;
    if (repeatOfferPayload && !selectedProduct) {
      duplicateOffer(
        appInfoData?.offerRequestType,
        t,
        repeatOfferPayload,
        (title, data) => {
          setAppStoreValue("appState", {
            ...appState,
            headerConfig: {
              ...appState.headerConfig,
              application_name: title,
            },
          });
          const inp_vehicle_category = data.inp_vehicle_category
            ? taxonomyFlat["inp_vehicle_category"][data.inp_vehicle_category]
            : null;
          const inp_lessee_county = data.inp_lessee_county
            ? taxonomyFlat["inp_lessee_county"][data.inp_lessee_county]
            : null;
          setFormStoreValues({
            subCategories: setSubcategoryOptions(inp_vehicle_category, t),
            cities: setCityOptions(inp_lessee_county, t),
            contractTypeOptions: setContractTypeOptions(data, t),
            fuelTypeOptions: setFuelTypeOptions(data, t),
            vehicleRegistrationOptions: setVehicleRegistrationOptions(data, t),
            sicCodeOptions: setSicGroupOptions(data, t),
            insurerCascoList: setInsurerCascoOptions(data, t),
            insurerMtplList: setInsurerMtplOptions(data, t),
            selectedCascoInsurers: [
              "omniasig",
              "asirom",
              "allianz",
              "groupama",
            ],
            selectedMtplInsurers: [
              "omniasig",
              "asirom",
              "allianz",
              "generali",
              "groupama",
              "grawe",
            ],
          });
        }
      );
    }
  }, [appInfoData, repeatOfferPayload, selectedProduct?.type]);

  // const initialOfferMemo = useMemo(() => {
  //   return transformVehiclePayload(initialOffer, t);
  // }, [initialOffer, t]);

  const offerMemo = useMemo(() => {
    const payload = transformVehiclePayload(offer, t);

    return payload;
  }, [offer, t]);

  const form = useForm({
    resolver,
    // defaultValues: initialOfferMemo,
    values: offerMemo,
  });

  if (!selectedProduct) return null;
  return (
    <ThemeProvider isDarkMode={appState.darkMode}>
      <NavigationBar>
        <Flex direction="row" justifyContent="flex-start">
          <Button
            appearance="subtle"
            icon={<ArrowLeftFilled />}
            onClick={() => {
              navigate(routes.listPage.getPath());
            }}
          >
            {t("bcr.Back.label")}
          </Button>
        </Flex>
      </NavigationBar>

      <MainTabList />

      <MainTabCard form={form} isLoading={isLoading} />
    </ThemeProvider>
  );
};

export default FormPageNew;
