import { useTheme } from "styled-components";
import {
  Body1,
  Button,
  Card,
  CardFooter,
  CardHeader,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import { BuildingRegular } from "@fluentui/react-icons";
import DatePickerField from "components/controls9/DatePickerField";
import NumberInputField from "components/controls9/NumberInputField";
import SelectField from "components/controls9/SelectField";
import TextInputField from "components/controls9/TextInputField";
import { MessageBarType } from "office-ui-fabric-react";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppStoreActions } from "store/AppStore";
import { useFormPageStore } from "store/FormPageStore";
import { resolverFn } from "utils/setYupLocale";
import { reverse_romanian_label, taxonomy } from "utils/types";
import { fieldToTab, getClasses } from "utils/utils";
import * as yup from "yup";
import { calculateCascoSchema } from "../useFormValidationSchema";
import { TabProps } from "types/types";
import CheckboxField from "components/controls9/CheckboxField";
import TabCard from "../TabCard";
import CardFooterWithInfoCalculation from "./CardFooterWithInfoCalculation";
import { toast } from "components/FluentToast";

type Props = {
  form: UseFormReturn<any, any>;
  isLoading: boolean;
};

const useStyles = makeStyles({
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  },
});

const OfferTab4 = (props: TabProps) => {
  const isLoading = props.isLoading;
  const { t } = useTranslation();
  const { watch, getValues, setError, clearErrors, setValue } = props.form;

  const styles = useStyles();
  const formPageStore = useFormPageStore();
  const { setNotificationMessage, clearAllNotifications } =
    useAppStoreActions();

  const [
    { selectedProduct, avarie8, offerTab, offer, progress, mainTab },
    { setFormStoreValue, setFormStoreValues },
  ] = formPageStore;
  const requiredMap = selectedProduct?.requiredMap;
  const disabledMap = selectedProduct?.disabledMap;
  const visibleMap = selectedProduct?.visibleMap;
  const disabledTab = progress.cascoOfferCreated || progress.mtplOfferCreated;

  watch((data, { name, type }) => {
    if (type === "change" && name === "avarie8") {
      setFormStoreValue("avarie8", data.avarie8 === "true");
    }
  });

  const labelStyle: any = (row, column) => {
    return {
      gridRow: `${row} / ${row}`,
      gridColumn: `${column} / ${column}`,
      textAlign: "right",
      marginTop: "6px",
    };
  };
  const fieldStyle = (row, column) => {
    return { gridRow: `${row} / ${row}`, gridColumn: `${column} / ${column}` };
  };
  const cardFooter = (
    <CardFooterWithInfoCalculation isLoading={isLoading} form={props.form}>
      <Button
        appearance="secondary"
        icon={<BuildingRegular fontSize={16} />}
        onClick={async () => {
          clearErrors();
          clearAllNotifications();

          resolverFn(
            selectedProduct.validationMap["offerTab4"](yup, t),
            getValues()
          ).then((res) => {
            if (Object.keys(res.errors).length > 0) {
              toast.error(t("bcr.validationErrors.label"));
              Object.keys(res.errors).forEach((key) => {
                setError(key, res.errors[key]);
                setNotificationMessage({
                  errors: [],
                  key: key,
                  messageBarType: MessageBarType.error,
                  fieldName: t("bcr." + key + ".label"),
                  handleNotificationClick: (e) => {
                    setFormStoreValues({
                      mainTab: { ...mainTab, selectedTab: "offer" },
                      offerTab: { ...offerTab, selectedTab: fieldToTab(key) },
                    });
                  },
                  notify: {
                    label: "label",
                    type: "error",
                    notifyCode: "notifyCode",
                    notifyText: (
                      <>
                        <b>{t("bcr." + key + ".label")}</b>
                        <br />
                        {res.errors[key].message}
                      </>
                    ),
                  },
                });
              });
            } else {
              setFormStoreValue("offerTab", {
                ...offerTab,
                selectedTab:
                  offer.offerRequestType !== "F100018" &&
                  offer.offerRequestType !== "F100056"
                    ? "tab5"
                    : "tab6",
                disabledTabs: offerTab.disabledTabs.filter((el) =>
                  offer.offerRequestType !== "F100018" &&
                  offer.offerRequestType !== "F100056"
                    ? el !== "tab5"
                    : el !== "tab6"
                ),
              });
            }
          });
        }}
      >
        {t("bcr.next.label")}
      </Button>
    </CardFooterWithInfoCalculation>
  );
  return (
    <>
      <TabCard footer={cardFooter}>
        <div className={styles.grid}>
          <div style={labelStyle(1, 1)}>
            {t(reverse_romanian_label("Greve, revolte, tulburari civile"))}
          </div>
          <div style={fieldStyle(1, 2)}>
            <CheckboxField
              form={props.form}
              isLoading={isLoading}
              name="avarie7"
              label={reverse_romanian_label("Greve, revolte, tulburari civile")}
              required={false}
              disabled={disabledTab || disabledMap?.avarie7}
              visible={visibleMap?.avarie7}
            />
          </div>

          <div style={labelStyle(2, 1)}>
            {t(reverse_romanian_label("Instalatie de lucru"))}
          </div>
          <div style={fieldStyle(2, 2)}>
            <CheckboxField
              form={props.form}
              isLoading={isLoading}
              name="avarie2"
              label={reverse_romanian_label("Instalatie de lucru")}
              required={false}
              disabled={disabledTab || disabledMap?.avarie2}
              visible={visibleMap?.avarie2}
            />
          </div>
          <div style={labelStyle(3, 1)}>
            {t(reverse_romanian_label("Avarii produse de marfa transp."))}
          </div>
          <div style={fieldStyle(3, 2)}>
            <CheckboxField
              form={props.form}
              isLoading={isLoading}
              name="avarie3"
              label={reverse_romanian_label("Avarii produse de marfa transp.")}
              required={false}
              disabled={disabledTab || disabledMap?.avarie3}
              visible={visibleMap?.avarie3}
            />
          </div>
          <div style={labelStyle(4, 1)}>
            {t(reverse_romanian_label("Incarcare/descarcare a marfurilor"))}
          </div>
          <div style={fieldStyle(4, 2)}>
            <CheckboxField
              form={props.form}
              isLoading={isLoading}
              name="avarie5"
              label={reverse_romanian_label(
                "Incarcare/descarcare a marfurilor"
              )}
              required={false}
              disabled={disabledTab || disabledMap?.avarie5}
              visible={visibleMap?.avarie5}
            />
          </div>
          <div style={labelStyle(5, 1)}>
            {t(reverse_romanian_label("Asistenta rutiera"))}
          </div>
          <div style={fieldStyle(5, 2)}>
            <CheckboxField
              form={props.form}
              isLoading={isLoading}
              name="avarie8"
              label={reverse_romanian_label("Asistenta rutiera")}
              required={false}
              disabled={disabledTab || disabledMap?.avarie8}
              visible={visibleMap?.avarie8}
              onChange={(value) => {
                setValue(
                  "avarie8",
                  value === "true" || value === true ? true : false
                );
                setFormStoreValue(
                  "avarie8",
                  value === "true" || value === true ? true : false
                );
                if (value === "false" || value === false)
                  setValue("inp_asistentarutiera", null);
              }}
            />
          </div>
          {avarie8 ? (
            <>
              <div style={labelStyle(6, 1)}>
                {t(reverse_romanian_label("Pachet asistenta rutiera"))}
                {requiredMap.inp_asistentarutiera ? "*" : ""}
              </div>
              <div style={fieldStyle(6, 2)}>
                <SelectField
                  isLoading={isLoading}
                  form={props.form}
                  name="inp_asistentarutiera"
                  label={reverse_romanian_label("Pachet asistenta rutiera")}
                  options={
                    disabledTab
                      ? taxonomy["inp_asistentarutiera"] || []
                      : taxonomy["inp_asistentarutiera_all"] || []
                  }
                  required={false}
                  disabled={disabledTab}
                  visible={visibleMap?.inp_asistentarutiera}
                />
              </div>
            </>
          ) : null}
          <div style={labelStyle(7, 1)}>
            {t(reverse_romanian_label("Anvelope / Jante"))}
          </div>
          <div style={fieldStyle(7, 2)}>
            {" "}
            <CheckboxField
              form={props.form}
              isLoading={isLoading}
              name="avarie6"
              label={reverse_romanian_label("Anvelope / Jante")}
              required={false}
              disabled={disabledTab || disabledMap?.avarie6}
              visible={visibleMap?.avarie6}
            />
          </div>
          <div style={labelStyle(8, 1)}>
            {t(reverse_romanian_label("Extindere teritoriala"))}
          </div>
          <div style={fieldStyle(8, 2)}>
            <CheckboxField
              form={props.form}
              isLoading={isLoading}
              name="avarie9"
              label={reverse_romanian_label("Extindere teritoriala")}
              required={false}
              disabled={disabledTab || disabledMap?.avarie9}
              visible={visibleMap?.avarie9}
            />
          </div>
          <div style={labelStyle(9, 1)}>
            {t(reverse_romanian_label("Extindere (ERS)"))}
          </div>
          <div style={fieldStyle(9, 2)}>
            <CheckboxField
              form={props.form}
              isLoading={isLoading}
              name="avarie10"
              label={reverse_romanian_label("Extindere (ERS)")}
              required={false}
              disabled={disabledTab || disabledMap?.avarie10}
              visible={visibleMap?.avarie10}
            />
          </div>
          <div style={labelStyle(10, 1)}>
            {t(reverse_romanian_label("Transportatori"))}
          </div>
          <div style={fieldStyle(10, 2)}>
            <CheckboxField
              form={props.form}
              isLoading={isLoading}
              name="avarie4"
              label={reverse_romanian_label("Transportatori")}
              required={false}
              disabled={disabledTab || disabledMap?.avarie4}
              visible={visibleMap?.avarie4}
            />
          </div>
        </div>
      </TabCard>
    </>
  );
};

export default OfferTab4;
