import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from "@fluentui/react-components";
import { ArrowDownloadRegular } from "@fluentui/react-icons";
import { VSpace } from "components/Spacer";
import { HorizontalSpacer, StatusCellSpinner } from "components/styled";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppStore } from "store/AppStore";
import { useFormPageStore } from "store/FormPageStore";
import { resolverFn, useYupValidationResolver } from "utils/setYupLocale";
import { reverse_romanian_label } from "utils/types";
import {
  downloadCalculationRequestResponseZipFile,
  fieldToTab,
  prepareFormItemsForSubmit,
  transformVehiclePayload,
} from "utils/utils";
import * as yup from "yup";
import CascoPremiumTable from "../CascoPremiumTable";
import MtplPremiumTable from "../MtplPremiumTable";
import SpinnerCard from "../SpinnerCard";
import { MessageBarType } from "office-ui-fabric-react";
import { toast } from "components/FluentToast";
import { Spinner } from "@fluentui/react";

const CardFooterWithInfoCalculation = ({ children, isLoading, form }) => {
  const { t } = useTranslation();
  const formPageStore = useFormPageStore();
  const [
    { romanianTranslations },
    { setNotificationMessage, clearAllNotifications },
  ] = useAppStore();
  const [
    {
      selectedProduct,
      selectedMtplInsurers,
      selectedCascoInsurers,
      progress,
      calculationInfo,
      offerLoadStatus,
      offerTab,
      mainTab,
    },
    {
      setFormStoreValue,
      calculateCasco,
      calculateCascoAndMtpl,
      setFormStoreValues,
    },
  ] = formPageStore;

  const schema = selectedProduct.validationMap.offerTab9(yup, t);
  const resolver = useYupValidationResolver(schema);
  const [showInfoCascoDialog, setShowInfoCascoDialog] = useState(false);
  const [showInfoCascoAndMtplDialog, setShowInfoCascoAndMtplDialog] =
    useState(false);

  const showSpinnerCascoState = useState(false);
  const showSpinnerMtplState = useState(false);

  const offerMemo = useMemo(() => {
    const payload = transformVehiclePayload(calculationInfo, t);

    return payload;
  }, [calculationInfo, t]);

  const dialogForm = useForm({
    resolver,
    // defaultValues: initialOfferMemo,
    values: {
      ...offerMemo,
      inp_policy_currency: form?.getValues()?.inp_policy_currency,
    },
  });
  // text={t(reverse_romanian_label("Info CASCO si RCA"))}
  const [mtplOfferErrorMessage, setMtplOfferErrorMessage] = useState(
    dialogForm.getValues("mtplOfferErrorMessage")
  );

  const [cascoOfferErrorMessage, setCascoOfferErrorMessage] = useState(
    dialogForm.getValues("cascoOfferErrorMessage")
  );

  const [calculationId, setCalculationId] = useState(
    dialogForm.getValues("id")
  );

  dialogForm.watch((data, { name, type }) => {
    // console.log("name", name, "type", type, "data", data);
    // if (name === "mtplOfferErrorMessage") {
    setMtplOfferErrorMessage(data.mtplOfferErrorMessage);
    setCascoOfferErrorMessage(data.cascoOfferErrorMessage);
    setCalculationId(data.id);
    // }
  });

  const onInfoCascoButtonClick = (e) => {
    form.clearErrors();
    clearAllNotifications();
    //calculateCascoSchema
    resolverFn(
      selectedProduct.validationMap["offerTabCasco"](yup, t),
      form.getValues()
    ).then((res) => {
      if (Object.keys(res.errors).length > 0) {
        toast.error(t("bcr.validationErrors.label"));
        Object.keys(res.errors).forEach((key) => {
          form.setError(key, res.errors[key]);
          setNotificationMessage({
            errors: [],
            key: key,
            messageBarType: MessageBarType.error,
            fieldName: t("bcr." + key + ".label"),
            handleNotificationClick: (e) => {
              setFormStoreValues({
                mainTab: { ...mainTab, selectedTab: "offer" },
                offerTab: { ...offerTab, selectedTab: fieldToTab(key) },
              });
            },
            notify: {
              label: "label",
              type: "error",
              notifyCode: "notifyCode",
              notifyText: (
                <>
                  <b>{t("bcr." + key + ".label")}</b>
                  <br />
                  {res.errors[key].message}
                </>
              ),
            },
          });
        });
      } else {
        setShowInfoCascoDialog(true);
        dialogForm.reset();
        // if passes, data is valid
        // clearResultFieldsCascoMtpl({
        //   data: form.getValues(),
        //   form: form,
        // });

        calculateCasco(
          {
            appInfo: {
              dataObj: {
                ...prepareFormItemsForSubmit(
                  {
                    ...form.getValues(),
                    selectedMtplInsurers: selectedMtplInsurers,
                    selectedCascoInsurers: selectedCascoInsurers,
                  },
                  romanianTranslations
                ),
              },
              resolvedProperties: {
                title: "BCR Leasing",
              },
            },
          },
          (retVal) => {
            setFormStoreValue("calculationInfo", retVal);
            // const preparedRetVal = prepareFormReturnValue(retVal, t);

            // for (const key in preparedRetVal) {
            //   dialogForm.setValue(key, preparedRetVal[key]);
            // }
          },
          (err) => {
            setNotificationMessage({
              errors: [],
              key: "key",
              messageBarType: MessageBarType.error,
              fieldName: "fieldName",
              handleNotificationClick: (e) => {},
              notify: {
                label: "label",
                type: "error",
                notifyCode: "notifyCode",
                notifyText: (
                  <>
                    <b>{"CalculateCASCO"}</b>
                    <br />
                    {err.response.data.Message}
                  </>
                ),
              },
            });
          }
        );
      }
    });
  };

  const onInfoCascoAndMtplButtonClick = (e) => {
    form.clearErrors();
    clearAllNotifications();
    //calculateCascoAndMtplSchema
    resolverFn(
      selectedProduct.validationMap["offerTab9"](
        yup,
        t,
        form.getValues().offerRequestType,
        form.getValues().include_mtpl_offer
      ),
      form.getValues()
    ).then((res) => {
      if (Object.keys(res.errors).length > 0) {
        toast.error(t("bcr.validationErrors.label"));
        Object.keys(res.errors).forEach((key) => {
          form.setError(key, res.errors[key]);
          setNotificationMessage({
            errors: [],
            key: key,
            messageBarType: MessageBarType.error,
            fieldName: t("bcr." + key + ".label"),
            handleNotificationClick: (e) => {
              setFormStoreValues({
                mainTab: { ...mainTab, selectedTab: "offer" },
                offerTab: { ...offerTab, selectedTab: fieldToTab(key) },
              });
            },
            notify: {
              label: "label",
              type: "error",
              notifyCode: "notifyCode",
              notifyText: (
                <>
                  <b>{t("bcr." + key + ".label")}</b>
                  <br />
                  {res.errors[key].message}
                </>
              ),
            },
          });
        });
      } else {
        setShowInfoCascoAndMtplDialog(true);

        // clearResultFieldsCascoMtpl({
        //   data: props.form.getValues(),
        //   form: props.form,
        // });
        // setFormStoreValue("accordionCollapsedMap", {
        //   ...accordionCollapsedMap,
        //   [reverse_romanian_label("Oferta CASCO")]: false,
        //   [reverse_romanian_label("Oferta RCA (RON)")]: false,
        // });

        calculateCascoAndMtpl(
          {
            appInfo: {
              dataObj: {
                ...prepareFormItemsForSubmit(
                  {
                    ...form.getValues(),
                    selectedMtplInsurers: selectedMtplInsurers,
                    selectedCascoInsurers: selectedCascoInsurers,
                  },
                  romanianTranslations
                ),
              },
              resolvedProperties: {
                title: "BCR Leasing",
              },
            },
          },

          (retVal) => {
            setFormStoreValue("calculationInfo", retVal);
            // const preparedRetVal = prepareFormReturnValue(retVal, t);

            // for (const key in preparedRetVal) {
            //   dialogForm.setValue(key, preparedRetVal[key]);
            // }
          },
          (err) => {
            setNotificationMessage({
              errors: [],
              key: "key",
              messageBarType: MessageBarType.error,
              fieldName: "fieldName",
              handleNotificationClick: (e) => {},
              notify: {
                label: "label",
                type: "error",
                notifyCode: "notifyCode",
                notifyText: (
                  <>
                    <b>{"CalculateMTPL"}</b>
                    <br />
                    {err.response.data.Message}
                  </>
                ),
              },
            });
          }
        );
      }
    });
  };
  const cardFooterWithInfoCalculation = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div style={{ flex: 1, display: "flex", justifyContent: "flex-start" }}>
        {selectedProduct.hasCasco &&
        !progress.cascoOfferCreated &&
        !form.getValues("pathToOfferPdf") ? (
          <>
            <Button
              onClick={onInfoCascoButtonClick}
              appearance="secondary"
              disabled={
                offerLoadStatus === "loading" ||
                offerTab.disabledTabs.includes("tab4")
              }
            >
              {t(reverse_romanian_label("Info CASCO"))}
            </Button>
            <HorizontalSpacer width={10} />
          </>
        ) : null}
        {selectedProduct.hasMtpl &&
        !progress.mtplOfferCreated &&
        !form.getValues("pathToOfferPdf") ? (
          <Button
            disabled={
              offerLoadStatus === "loading" ||
              offerTab.disabledTabs.includes("tab8")
            }
            onClick={onInfoCascoAndMtplButtonClick}
            appearance="secondary"
          >
            {t(reverse_romanian_label("Info CASCO si RCA"))}
          </Button>
        ) : null}
        <Dialog open={showInfoCascoDialog}>
          <DialogSurface style={{ maxWidth: "700px" }}>
            <DialogBody>
              <DialogTitle>
                {t(reverse_romanian_label("Info CASCO"))}
              </DialogTitle>
              <DialogContent>
                {offerLoadStatus !== "loading" ? (
                  <>
                    <VSpace height={20} />
                    {selectedProduct?.hasCasco ? (
                      <>
                        <h3>{t("bcr.offerTabCascoOffer.label")}</h3>
                        <VSpace height={10} />
                        <CascoPremiumTable form={dialogForm} />
                      </>
                    ) : null}
                    {cascoOfferErrorMessage ? (
                      <Dialog>
                        <DialogTrigger disableButtonEnhancement>
                          <Button appearance="secondary">
                            {t("bcr.Arata_erori.label")}
                          </Button>
                        </DialogTrigger>
                        <DialogSurface style={{ maxWidth: "876px" }}>
                          <DialogBody>
                            <DialogTitle>CASCO Errors</DialogTitle>
                            <DialogContent>
                              <code
                                dangerouslySetInnerHTML={{
                                  __html: cascoOfferErrorMessage
                                    ? cascoOfferErrorMessage
                                    : "",
                                }}
                              ></code>
                            </DialogContent>
                            <DialogActions>
                              <DialogTrigger disableButtonEnhancement>
                                <Button appearance="secondary">
                                  {t("bcr.closeButton.label")}
                                </Button>
                              </DialogTrigger>
                            </DialogActions>
                          </DialogBody>
                        </DialogSurface>
                      </Dialog>
                    ) : null}
                  </>
                ) : (
                  <SpinnerCard width="500px" height="256px" />
                )}
              </DialogContent>
              <DialogActions>
                {offerLoadStatus !== "loading" &&
                (form.getValues("highestRole") === "admin" ||
                  form.getValues("highestRole") === "manager") ? (
                  <>
                    <Button
                      icon={
                        showSpinnerCascoState[0] ? (
                          <StatusCellSpinner />
                        ) : (
                          <ArrowDownloadRegular />
                        )
                      }
                      disabled={showSpinnerCascoState[0]}
                      appearance="secondary"
                      style={{ marginRight: "10px" }}
                      onClick={(e) => {
                        downloadCalculationRequestResponseZipFile(
                          calculationId,
                          "casco",
                          showSpinnerCascoState
                        );
                      }}
                    ></Button>
                  </>
                ) : null}

                <DialogTrigger disableButtonEnhancement>
                  <Button
                    appearance="secondary"
                    onClick={() => setShowInfoCascoDialog(false)}
                  >
                    Close
                  </Button>
                </DialogTrigger>
              </DialogActions>
            </DialogBody>
          </DialogSurface>
        </Dialog>

        <Dialog open={showInfoCascoAndMtplDialog}>
          <DialogSurface style={{ maxWidth: "700px" }}>
            <DialogBody>
              <DialogTitle>
                {t(reverse_romanian_label("Info CASCO si RCA"))}
              </DialogTitle>
              <DialogContent>
                {offerLoadStatus !== "loading" ? (
                  <>
                    <VSpace height={20} />
                    {selectedProduct?.hasMtpl ? (
                      <>
                        <h3>{t("bcr.offerTabMtplOffer.label")}</h3>
                        <VSpace height={10} />
                        <MtplPremiumTable form={dialogForm} />
                        <VSpace height={20} />
                      </>
                    ) : null}
                    {mtplOfferErrorMessage ? (
                      <Dialog>
                        <DialogTrigger disableButtonEnhancement>
                          <Button appearance="secondary">
                            {t("bcr.Arata_erori.label")}
                          </Button>
                        </DialogTrigger>
                        <DialogSurface style={{ maxWidth: "876px" }}>
                          <DialogBody>
                            <DialogTitle>MTPL Errors</DialogTitle>
                            <DialogContent>
                              <code
                                dangerouslySetInnerHTML={{
                                  __html: mtplOfferErrorMessage
                                    ? mtplOfferErrorMessage
                                    : "",
                                }}
                              ></code>
                            </DialogContent>
                            <DialogActions>
                              <DialogTrigger disableButtonEnhancement>
                                <Button appearance="secondary">
                                  {t("bcr.closeButton.label")}
                                </Button>
                              </DialogTrigger>
                            </DialogActions>
                          </DialogBody>
                        </DialogSurface>
                      </Dialog>
                    ) : null}
                  </>
                ) : (
                  <SpinnerCard width="500px" height="320px" />
                )}
              </DialogContent>

              <DialogActions>
                {offerLoadStatus !== "loading" &&
                (form.getValues("highestRole") === "admin" ||
                  form.getValues("highestRole") === "manager") ? (
                  <>
                    <Button
                      icon={
                        showSpinnerMtplState[0] ? (
                          <StatusCellSpinner />
                        ) : (
                          <ArrowDownloadRegular />
                        )
                      }
                      disabled={showSpinnerMtplState[0]}
                      appearance="secondary"
                      style={{ marginRight: "10px" }}
                      onClick={(e) => {
                        downloadCalculationRequestResponseZipFile(
                          calculationId,
                          "mtpl",
                          showSpinnerMtplState
                        );
                      }}
                    ></Button>
                  </>
                ) : null}
                <DialogTrigger disableButtonEnhancement>
                  <Button
                    appearance="secondary"
                    onClick={() => setShowInfoCascoAndMtplDialog(false)}
                  >
                    {t("bcr.closeButton.label")}
                  </Button>
                </DialogTrigger>
              </DialogActions>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      </div>
      <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
        {children}
      </div>
    </div>
  );
  console.log(mtplOfferErrorMessage);
  console.log(cascoOfferErrorMessage);
  console.log(mtplOfferErrorMessage + cascoOfferErrorMessage);

  return cardFooterWithInfoCalculation;
};

export default CardFooterWithInfoCalculation;
