import { useTheme } from "styled-components";
import {
  Body1,
  Button,
  ButtonProps,
  Card,
  CardFooter,
  CardHeader,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import { BuildingRegular } from "@fluentui/react-icons";
import DatePickerField from "components/controls9/DatePickerField";
import NumberInputField from "components/controls9/NumberInputField";
import SelectField from "components/controls9/SelectField";
import TextInputField from "components/controls9/TextInputField";
import { MessageBarType } from "office-ui-fabric-react";
import React, { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppStoreActions } from "store/AppStore";
import { useFormPageStore } from "store/FormPageStore";
import { resolverFn } from "utils/setYupLocale";
import {
  inp_vehicle_brand,
  reverse_romanian_label,
  taxonomy,
} from "utils/types";
import {
  fieldToTab,
  prepareFormReturnValue,
  setFuelTypeOptions,
  setSubcategoryOptions,
  setValueOfSubCategory,
} from "utils/utils";
import * as yup from "yup";
import { calculateCascoSchema } from "../useFormValidationSchema";
import { TabProps } from "types/types";
import TabCard from "../TabCard";
import CardFooterWithInfoCalculation from "./CardFooterWithInfoCalculation";
import AutomaticOperationButton from "components/AutomaticOperationButton";
import moment from "moment";
import { InsuranceProductType } from "types/products";
import { toast } from "components/FluentToast";

type Props = {
  form: UseFormReturn<any, any>;
  isLoading: boolean;
};

const useStyles = makeStyles({
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  },
});

const OfferTab2 = (props: TabProps) => {
  const isLoading = props.isLoading;
  const { t } = useTranslation();
  const { watch, setValue, getValues, setError, clearErrors } = props.form;

  const styles = useStyles();
  const formPageStore = useFormPageStore();
  const { setNotificationMessage, clearAllNotifications } =
    useAppStoreActions();

  const [
    {
      selectedProduct,
      offerTab,
      mainTab,
      vehicleRegistrationOptions,
      subCategories,
      progress,
      offer,
      showLicensePlate,
    },
    { setFormStoreValue, setFormStoreValues, getVehicleInfo },
  ] = formPageStore;

  const disabledTab = progress.cascoOfferCreated || progress.mtplOfferCreated;

  const theme = useTheme();
  const requiredMap = selectedProduct?.requiredMap;
  const disabledMap = selectedProduct?.disabledMap;
  const visibleMap = selectedProduct?.visibleMap;

  watch((data, { name, type }) => {
    if (type === "change" && name === "inp_vehicle_category") {
      setFormStoreValue(
        "subCategories",
        setSubcategoryOptions(data.inp_vehicle_category, t)
      );
      const subcatVal = setValueOfSubCategory(data.inp_vehicle_category, t);
      setValue("inp_vehicle_subcategory", subcatVal ? subcatVal.value : null);
    }
    if (
      // (data.offerRequestType === "F100056" ||
      //   data.offerRequestType === "F100018") &&
      type === "change" &&
      name === "inp_vehicle_registration"
    ) {
      if (
        data.inp_vehicle_registration &&
        (data.inp_vehicle_registration ===
          "bcr.Registration_type_IN_ORDER_TO_REGISTER" ||
          data.inp_vehicle_registration ===
            "bcr.Registration_type_IN_ORDER_TO_MATRICULATE")
      ) {
        setValue("inp_vehicle_licenseplate", "");
        setFormStoreValue("showLicensePlate", false);
      } else {
        setFormStoreValue("showLicensePlate", true);
      }
    }
  });

  const labelStyle: any = (row, column) => {
    return {
      gridRow: `${row} / ${row}`,
      gridColumn: `${column} / ${column}`,
      textAlign: "right",
      marginTop: "6px",
    };
  };
  const fieldStyle = (row, column) => {
    return { gridRow: `${row} / ${row}`, gridColumn: `${column} / ${column}` };
  };

  const cardFooter = (
    <CardFooterWithInfoCalculation isLoading={isLoading} form={props.form}>
      <Button
        appearance="secondary"
        icon={<BuildingRegular fontSize={16} />}
        onClick={async () => {
          clearErrors();
          clearAllNotifications();

          resolverFn(
            selectedProduct.validationMap["offerTab2"](yup, t),
            getValues()
          ).then((res) => {
            if (Object.keys(res.errors).length > 0) {
              toast.error(t("bcr.validationErrors.label"));
              Object.keys(res.errors).forEach((key) => {
                setError(key, res.errors[key]);
                setNotificationMessage({
                  errors: [],
                  key: key,
                  messageBarType: MessageBarType.error,
                  fieldName: t("bcr." + key + ".label"),
                  handleNotificationClick: (e) => {
                    setFormStoreValues({
                      mainTab: { ...mainTab, selectedTab: "offer" },
                      offerTab: { ...offerTab, selectedTab: fieldToTab(key) },
                    });
                  },
                  notify: {
                    label: "label",
                    type: "error",
                    notifyCode: "notifyCode",
                    notifyText: (
                      <>
                        <b>{t("bcr." + key + ".label")}</b>
                        <br />
                        {res.errors[key].message}
                      </>
                    ),
                  },
                });
              });
            } else {
              setFormStoreValue("offerTab", {
                ...offerTab,
                selectedTab: selectedProduct.hasCasco
                  ? "tab4"
                  : offer.offerRequestType !== "F100056"
                  ? "tab5"
                  : "tab6",
                disabledTabs: offerTab.disabledTabs.filter(
                  (el) =>
                    el !==
                    (selectedProduct.hasCasco
                      ? "tab4"
                      : offer.offerRequestType !== "F100056"
                      ? "tab5"
                      : "tab6")
                ),
              });
            }
          });
        }}
      >
        {t("bcr.next.label")}
      </Button>
    </CardFooterWithInfoCalculation>
  );
  const fleetOptions = taxonomy["inp_vehicle_fleet"].filter((item) => {
    if (
      getValues("offerRequestType") === "F100018" &&
      item.value === "bcr.Vehicle.CAR_FLEET_BETWEEN_5-20"
    )
      return false;
    return true;
  });

  useEffect(() => {
    if (
      getValues("offerRequestType") === "F100018" &&
      !getValues("inp_vehicle_fleet")
    ) {
      setValue("inp_vehicle_fleet", "bcr.Vehicle.CAR_FLEET_BETWEEN_0-4");
      // setFormStoreValue("selectedProduct", {
      //   ...selectedProduct,
      //   disabledMap: {
      //     ...selectedProduct.disabledMap,
      //     inp_vehicle_fleet: true,
      //   },
      // });
    }
  }, [getValues("offerRequestType"), selectedProduct]);
  return (
    <>
      <TabCard footer={cardFooter}>
        <div className={styles.grid}>
          <div style={labelStyle(1, 1)}>
            {t(reverse_romanian_label("Serie sasiu"))}
            {requiredMap.inp_vehicle_vin ? "*" : ""}
          </div>
          <div style={fieldStyle(1, 2)}>
            <TextInputField
              form={props.form}
              isLoading={isLoading}
              name="inp_vehicle_vin"
              label={reverse_romanian_label("Serie sasiu")}
              required={false}
              disabled={disabledTab || disabledMap?.inp_vehicle_vin}
              visible={visibleMap?.inp_vehicle_vin}
              contentAfter={
                <AutomaticOperationButton
                  id="btn_populate_vehicle_data"
                  aria-label="Populate Vehicle data"
                  onClick={(e) => {
                    if (
                      getValues("inp_vehicle_vin") &&
                      getValues("inp_vehicle_vin") !== ""
                    ) {
                      getVehicleInfo(
                        getValues("inp_vehicle_vin"),
                        (retVal) => {
                          try {
                            const preparedRetVal: any = prepareFormReturnValue(
                              retVal,
                              t
                            );
                            for (const key1 in retVal) {
                              if (key1 === "inp_vehicle_brand") continue;
                              let found = false;
                              for (const key2 in preparedRetVal) {
                                if (key1 === key2) {
                                  found = true;
                                  break;
                                }
                              }
                              if (!found) {
                                setValue(key1, null);
                              }
                            }
                            for (const key in preparedRetVal) {
                              setValue(
                                key,
                                preparedRetVal[key]?.value
                                  ? preparedRetVal[key]?.value
                                  : preparedRetVal[key]
                              );
                              if (
                                key === "inp_vehicle_category" &&
                                preparedRetVal[key]?.value
                              ) {
                                setFormStoreValues({
                                  subCategories: setSubcategoryOptions(
                                    preparedRetVal[key]?.value,
                                    t
                                  ),
                                });
                                // setValue(
                                //   "inp_vehicle_subcategory",
                                //   setValueOfSubCategory(
                                //     preparedRetVal[key].value,
                                //     t
                                //   ).value
                                // );
                              }
                            }
                            if (
                              retVal.inp_vehicle_brand &&
                              !preparedRetVal.inp_vehicle_brand
                            ) {
                              let found = false;
                              for (const key in inp_vehicle_brand) {
                                if (
                                  key.toUpperCase() ===
                                  retVal.inp_vehicle_brand.toUpperCase()
                                ) {
                                  setValue(
                                    "inp_vehicle_brand",
                                    inp_vehicle_brand[key]
                                  );
                                  found = true;
                                  break;
                                }
                              }
                              if (!found) {
                                setValue("inp_vehicle_brand", null);
                              }
                            }
                          } catch (e) {
                            console.log(e);
                          }
                        },
                        (err) => {
                          setNotificationMessage({
                            errors: [],
                            key: "key",
                            messageBarType: MessageBarType.error,
                            fieldName: "fieldName",
                            handleNotificationClick: (e) => {},
                            notify: {
                              label: "label",
                              type: "error",
                              notifyCode: "notifyCode",
                              notifyText: (
                                <>
                                  <b>{"GetVehicleInfo"}</b>
                                  <br />
                                  {err.response.data.Message}
                                </>
                              ),
                            },
                          });
                        }
                      );
                    } else {
                      toast.error(t("greco.form.errors.required"));
                    }
                  }}
                />
              }
            />
          </div>
          <div style={labelStyle(2, 1)}>
            {t(reverse_romanian_label("Marca"))}
            {requiredMap.inp_vehicle_brand ? "*" : ""}
          </div>
          <div style={fieldStyle(2, 2)}>
            <SelectField
              isLoading={isLoading}
              form={props.form}
              name="inp_vehicle_brand"
              label={reverse_romanian_label("Marca")}
              options={taxonomy["inp_vehicle_brand"] || []}
              required={requiredMap?.inp_vehicle_brand}
              disabled={disabledTab || disabledMap?.inp_vehicle_brand}
              visible={visibleMap?.inp_vehicle_brand}
            />
          </div>
          <div style={labelStyle(3, 1)}>
            {t(reverse_romanian_label("Model"))}
            {requiredMap.inp_vehicle_model ? "*" : ""}
          </div>
          <div style={fieldStyle(3, 2)}>
            <TextInputField
              form={props.form}
              isLoading={isLoading}
              name="inp_vehicle_model"
              label={reverse_romanian_label("Model")}
              required={requiredMap?.inp_vehicle_model}
              disabled={disabledTab || disabledMap?.inp_vehicle_model}
              visible={visibleMap?.inp_vehicle_model}
            />
          </div>
          <div style={labelStyle(4, 1)}>
            {t(reverse_romanian_label("Combustibil"))}
            {requiredMap.inp_vehicle_fuel ? "*" : ""}
          </div>
          <div style={fieldStyle(4, 2)}>
            <SelectField
              isLoading={isLoading}
              form={props.form}
              name="inp_vehicle_fuel"
              label={reverse_romanian_label("Combustibil")}
              options={setFuelTypeOptions(offer, t)}
              required={true}
              disabled={disabledTab || disabledMap?.inp_vehicle_fuel}
              visible={visibleMap?.inp_vehicle_fuel}
            />
          </div>
          <div style={labelStyle(5, 1)}>
            {t(reverse_romanian_label("Masa max. autorizata (kg)"))}
            {requiredMap.inp_vehicle_maximumgrossweight ? "*" : ""}
          </div>
          <div style={fieldStyle(5, 2)}>
            <NumberInputField
              form={props.form}
              isLoading={isLoading}
              name="inp_vehicle_maximumgrossweight"
              label={reverse_romanian_label("Masa max. autorizata (kg)")}
              required={true}
              disabled={
                disabledTab || disabledMap?.inp_vehicle_maximumgrossweight
              }
              isNegativeAllowed={false}
              visible={visibleMap?.inp_vehicle_maximumgrossweight}
            />
          </div>
          <div style={labelStyle(6, 1)}>
            {t(reverse_romanian_label("Nr. locuri"))}
            {requiredMap.inp_vehicle_places ? "*" : ""}
          </div>
          <div style={fieldStyle(6, 2)}>
            <NumberInputField
              form={props.form}
              isLoading={isLoading}
              name="inp_vehicle_places"
              label={reverse_romanian_label("Nr. locuri")}
              required={true}
              disabled={disabledTab || disabledMap?.inp_vehicle_places}
              isNegativeAllowed={false}
              visible={visibleMap?.inp_vehicle_places}
            />
          </div>
          <div style={labelStyle(7, 1)}>
            {t(reverse_romanian_label("Putere (kW)"))}
            {requiredMap.inp_vehicle_kw ? "*" : ""}
          </div>
          <div style={fieldStyle(7, 2)}>
            <NumberInputField
              form={props.form}
              isLoading={isLoading}
              name="inp_vehicle_kw"
              label={reverse_romanian_label("Putere (kW)")}
              required={false}
              disabled={disabledTab || disabledMap?.inp_vehicle_kw}
              isNegativeAllowed={false}
              isFloat={false}
              visible={visibleMap?.inp_vehicle_kw}
            />
          </div>
          <div style={labelStyle(8, 1)}>
            {t(reverse_romanian_label("Cilindree"))}
            {requiredMap.inp_vehicle_cubiccapacity ? "*" : ""}
          </div>
          <div style={fieldStyle(8, 2)}>
            <NumberInputField
              isLoading={isLoading}
              form={props.form}
              name="inp_vehicle_cubiccapacity"
              label={reverse_romanian_label("Cilindree")}
              required={false}
              disabled={disabledTab || disabledMap?.inp_vehicle_cubiccapacity}
              isNegativeAllowed={false}
              visible={visibleMap?.inp_vehicle_cubiccapacity}
            />
          </div>
          <div style={labelStyle(9, 1)}>
            {t(reverse_romanian_label("Categoria"))}
            {requiredMap.inp_vehicle_category ? "*" : ""}
          </div>
          <div style={fieldStyle(9, 2)}>
            <SelectField
              isLoading={isLoading}
              form={props.form}
              name="inp_vehicle_category"
              label={reverse_romanian_label("Categoria")}
              options={
                getValues("offerRequestType") === "F100018"
                  ? taxonomy["inp_vehicle_category"]
                      .slice(0, 4)
                      .concat(taxonomy["inp_vehicle_category"].slice(6, 8))
                  : taxonomy["inp_vehicle_category"] || []
              }
              required={true}
              disabled={disabledTab || disabledMap?.inp_vehicle_category}
              visible={visibleMap?.inp_vehicle_category}
            />
          </div>
          <div style={labelStyle(10, 1)}>
            {t(reverse_romanian_label("Subcategorie"))}
            {requiredMap.inp_vehicle_subcategory ? "*" : ""}
          </div>
          <div style={fieldStyle(10, 2)}>
            <SelectField
              isLoading={isLoading}
              form={props.form}
              name="inp_vehicle_subcategory"
              label={reverse_romanian_label("Subcategorie")}
              options={subCategories || []}
              required={false} //
              disabled={disabledTab || disabledMap?.inp_vehicle_subcategory}
              visible={visibleMap?.inp_vehicle_subcategory}
              onChange={(value) => {
                setFormStoreValues({
                  inp_vehicle_subcategory: value,
                });
              }}
              // refreshOn: inp_vehicle_category
            />
          </div>
          <div style={labelStyle(11, 1)}>
            {t(reverse_romanian_label("An fabricatie"))}
            {requiredMap.inp_vehicle_construction ? "*" : ""}
          </div>
          <div style={fieldStyle(11, 2)}>
            <SelectField
              isLoading={isLoading}
              form={props.form}
              name="inp_vehicle_construction"
              label={reverse_romanian_label("An fabricatie")}
              options={
                selectedProduct?.type === InsuranceProductType.generalBusiness
                  ? taxonomy["inp_vehicle_construction100"]
                  : taxonomy["inp_vehicle_construction"] || []
              }
              required={true}
              disabled={disabledTab || disabledMap?.inp_vehicle_construction}
              visible={visibleMap?.inp_vehicle_construction}
            />
          </div>
          <div style={labelStyle(12, 1)}>
            {t(reverse_romanian_label("An prima inmatriculare"))}
            {requiredMap.inp_vehicle_firstreg ? "*" : ""}
          </div>
          <div style={fieldStyle(12, 2)}>
            <SelectField
              isLoading={isLoading}
              form={props.form}
              name="inp_vehicle_firstreg"
              label={reverse_romanian_label("An prima inmatriculare")}
              options={
                selectedProduct?.type === InsuranceProductType.generalBusiness
                  ? taxonomy["inp_vehicle_firstreg100"]
                  : taxonomy["inp_vehicle_firstreg"] || []
              }
              required={true}
              disabled={disabledTab || disabledMap?.inp_vehicle_firstreg}
              visible={visibleMap?.inp_vehicle_firstreg}
            />
          </div>
          <div style={labelStyle(13, 1)}>
            {t(reverse_romanian_label("Stare autovehicul"))}
            {requiredMap.inp_vehicle_state ? "*" : ""}
          </div>
          <div style={fieldStyle(13, 2)}>
            <SelectField
              isLoading={isLoading}
              form={props.form}
              name="inp_vehicle_state"
              label={reverse_romanian_label("Stare autovehicul")}
              options={taxonomy["inp_vehicle_state"] || []}
              required={requiredMap?.inp_vehicle_state}
              disabled={disabledTab || disabledMap?.inp_vehicle_state}
              visible={visibleMap?.inp_vehicle_state}
            />
          </div>
          <div style={labelStyle(14, 1)}>
            {t(reverse_romanian_label("Utilizare autovehicul"))}
            {requiredMap.inp_vehicle_carusage ? "*" : ""}
          </div>{" "}
          <div style={fieldStyle(14, 2)}>
            <SelectField
              isLoading={isLoading}
              form={props.form}
              name="inp_vehicle_carusage"
              label={reverse_romanian_label("Utilizare autovehicul")}
              options={taxonomy["inp_vehicle_carusage"] || []}
              required={true}
              disabled={disabledTab || disabledMap?.inp_vehicle_carusage}
              visible={visibleMap?.inp_vehicle_carusage}
            />
          </div>
          <div style={labelStyle(15, 1)}>
            {t(reverse_romanian_label("Parc auto"))}
            {requiredMap.inp_vehicle_fleet ? "*" : ""}
          </div>{" "}
          <div style={fieldStyle(15, 2)}>
            <SelectField
              isLoading={isLoading}
              form={props.form}
              name="inp_vehicle_fleet"
              label={reverse_romanian_label("Parc auto")}
              options={fleetOptions}
              required={true}
              disabled={disabledTab || disabledMap?.inp_vehicle_fleet}
              visible={visibleMap?.inp_vehicle_fleet}
            />
          </div>
          {visibleMap?.inp_vehicle_registration ? (
            <>
              <div style={labelStyle(16, 1)}>
                {t(reverse_romanian_label("Tip inmatriculare"))}
                {requiredMap.inp_vehicle_registration ? "*" : ""}
              </div>
              <div style={fieldStyle(16, 2)}>
                <SelectField
                  form={props.form}
                  isLoading={isLoading}
                  name="inp_vehicle_registration"
                  label={reverse_romanian_label("Tip inmatriculare")}
                  options={vehicleRegistrationOptions}
                  required={false}
                  disabled={
                    disabledTab || disabledMap?.inp_vehicle_registration
                  }
                  visible={visibleMap?.inp_vehicle_registration}
                />
              </div>
            </>
          ) : null}
          {visibleMap?.inp_vehicle_licenseplate && showLicensePlate ? (
            <>
              <div style={labelStyle(17, 1)}>
                {t(reverse_romanian_label("Nr. inmatriculare"))}
                {requiredMap.inp_vehicle_licenseplate ? "*" : ""}
              </div>
              <div style={fieldStyle(17, 2)}>
                <TextInputField
                  form={props.form}
                  isLoading={isLoading}
                  name="inp_vehicle_licenseplate"
                  label={reverse_romanian_label("Nr. inmatriculare")}
                  required={false}
                  disabled={
                    disabledTab || disabledMap?.inp_vehicle_licenseplate
                  }
                  visible={visibleMap?.inp_vehicle_licenseplate}
                />
              </div>
            </>
          ) : null}
          {selectedProduct?.visibleMap.inp_sicgroup ? (
            <>
              <div style={labelStyle(18, 1)}>
                {t(reverse_romanian_label("Carte CIV (A000000)"))}
                {requiredMap.inp_vehicle_id ? "*" : ""}
              </div>
              <div style={fieldStyle(18, 2)}>
                <TextInputField
                  form={props.form}
                  isLoading={isLoading}
                  name="inp_vehicle_id"
                  label={reverse_romanian_label("Carte CIV (A000000)")}
                  required={false}
                  disabled={disabledTab || disabledMap?.inp_vehicle_id}
                  visible={visibleMap?.inp_vehicle_id}
                />
              </div>{" "}
            </>
          ) : null}
          <div style={labelStyle(19, 1)}>
            {t(reverse_romanian_label("Data expirare ITP"))}
            {requiredMap.inp_vehicle_registration_expiration_date ? "*" : ""}
          </div>
          <div style={fieldStyle(19, 2)}>
            <div style={{ display: "flex" }}>
              <DatePickerField
                isLoading={isLoading}
                form={props.form}
                name="inp_vehicle_registration_expiration_date"
                label={reverse_romanian_label("Data expirare ITP")}
                data-format="DD.MM.YYYY"
                data-dateFormat="DD.MM.YYYY"
                required={requiredMap?.inp_vehicle_registration_expiration_date}
                disabled={
                  disabledTab ||
                  disabledMap?.inp_vehicle_registration_expiration_date
                }
                visible={visibleMap?.inp_vehicle_registration_expiration_date}
                minDate={moment().startOf("day").toDate()}
              />
            </div>
          </div>
        </div>
      </TabCard>
    </>
  );
};

export default OfferTab2;
