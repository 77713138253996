import { Body1Strong, Select } from "@fluentui/react-components";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ErrorMsg from "./ErrorMsg";
import { FieldContainer } from "./FieldContainer";

type Props = {
  form: any;
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  options: any[];
  deprecatedOptions?: any[];
  value?: any;
  visible?: boolean;
  isLoading: boolean;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  width?: string;
  styles?: any;
};

export const SelectField = ({
  name,
  label,
  required = false,
  disabled = false,
  options,
  deprecatedOptions,
  form,
  visible,
  isLoading,
  onBlur = null,
  onChange = null,
  width = "100%",
  styles,
}: Props) => {
  const {
    control,
    formState: { errors },
  } = form;
  const { t } = useTranslation();
  if (!visible) return null;
  let readOnlyOptions = [...options];
  if (deprecatedOptions && deprecatedOptions.length > 0) {
    readOnlyOptions.push(...deprecatedOptions);
  }

  return (
    <FieldContainer
      isReadOnly={disabled}
      isTooltipHidden={disabled}
      tooltipText={label}
    >
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          let value = "";
          if (!field.value) value = "";
          if (!readOnlyOptions || readOnlyOptions.length === 0)
            value = field.value;
          for (let i = 0; i < readOnlyOptions.length; i++) {
            if (readOnlyOptions[i].value === field.value) value = field.value;
            if (readOnlyOptions[i].value === String(field.value).toUpperCase())
              value = String(field.value).toUpperCase();
          }
          console.log(options, field);
          return (
            <>
              {disabled ? (
                <Body1Strong
                  id={`select_readonly_${name}`}
                  style={{
                    marginLeft: "15px",
                    color: isLoading ? "#888888" : null,
                  }}
                >
                  {t(
                    readOnlyOptions.find((el) => {
                      return el.value === value;
                    })?.label
                  )}
                  <input
                    type="hidden"
                    name={name}
                    value={field.value || ""}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                </Body1Strong>
              ) : (
                <Select
                  required={required}
                  disabled={disabled || isLoading}
                  id={name}
                  name={field.name}
                  onBlur={(e) => {
                    field.onBlur();
                    onBlur && onBlur(e);
                  }}
                  onChange={(e, data) => {
                    field.onChange(data.value ? data.value : null);
                    onChange && onChange(data.value ? data.value : null);
                  }}
                  value={value || ""}
                  // placeholder={
                  //   disabled ? "" : t("greco.form.selectPlaceholder")
                  // }
                  // noOptionsMessage={() => t("greco.noResults")}
                  // isDisabled={disabled}
                  // isClearable
                  // inputId={name + "-input"}
                  // defaultValue={defaultValues[name]}
                >
                  <option key={"none"} value={""}></option>
                  {options.map((tax) => (
                    <option key={tax.value} value={tax.value}>
                      {t(tax.label)}
                    </option>
                  ))}
                </Select>
              )}
              {errors && errors[name] ? (
                <ErrorMsg id={name + "_error"}>{errors[name].message}</ErrorMsg>
              ) : null}
            </>
          );
        }}
      />
    </FieldContainer>
  );
};

export default SelectField;
